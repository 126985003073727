import { createApp, watch } from 'vue';
import { createAuth0 } from '@auth0/auth0-vue'
import 'buefy/dist/buefy.css'
import Buefy from 'buefy';

import App from '@/App.vue';
import router from './router';
import { createPcStore } from '@/store';

import Loading from '@/components/common/Loading.vue';
import PageLoader from '@/components/common/PageLoader.vue';

import "./assets/main.css"

import { createApi, useApi, getVueAppEnv } from '@/services/apiService';
import telemetry from '@/services/telemetry';

// import { Auth0Plugin } from './auth';
import { ToastPlugin } from '@/services/toastService';

async function main() {
  const vueAppEnvResult = await getVueAppEnv();
  const vueAppEnv = vueAppEnvResult.data;
  console.warn(vueAppEnv)

  const app = createApp(App)

  app.component('Loading', Loading)
  app.component('PageLoader', PageLoader)

  app.use({
    install(app, _opts) {
      app.config.globalProperties.$env = vueAppEnv
      app.provide("$env", vueAppEnv)
    },
  })

  app.provide("$router", router)
  app.use(router)

  app.use(telemetry, { router, env: vueAppEnv });
  const $telemetry = app.config.globalProperties.$telemetry

  // $auth, $api, $store
  {
    const redirectUri = new URL('/', window.location.origin)
    console.log('Auth Redirect URI: ' + redirectUri)

    const auth0 = createAuth0({
      domain: vueAppEnv.VUE_APP_AUTH0_DOMAIN,
      clientId: vueAppEnv.VUE_APP_AUTH0_CLIENT_ID,
      cacheLocation: 'localstorage',
      authorizationParams: {
        // connection: "email",
        // prompt: 'select_account',
        // display: "wap",oca
        // screen_hint: "login",
        // login_hint: "mpekar@revolutioncompany.com",
        redirect_uri: redirectUri,
        audience: vueAppEnv.VUE_APP_AUTH0_API_AUDIENCE
      },
      useRefreshTokens: true
    })

    app.use(auth0)

    watch(() => auth0.user.value, () => {
      const user = auth0?.user?.value || {}

      if (user?.sub) {
        $telemetry.setUser({
          id: user.sub,
          email: user.email
        })
      }
      else {
        $telemetry.setUser(null)
      }
    })

    app.use(createApi(auth0))

    const $api = useApi()

    // const analytics = app.config.globalProperties.$analytics

    const store = createPcStore($api)
    app.provide("$store", store)
    app.use(store)
  }

  app.use(Buefy);

  const RouteWizardRoute = router.resolve({ name: 'dispatcher' });
  const redirectUri = new URL(RouteWizardRoute.href, window.location.origin);
  console.log('Redirect URI: ' + redirectUri);

  app.use(ToastPlugin);

  // app.use(Auth0Plugin, {
  //   domain: vueAppEnv.VUE_APP_AUTH0_DOMAIN,
  //   clientId: vueAppEnv.VUE_APP_AUTH0_CLIENT_ID,
  //   audience: vueAppEnv.VUE_APP_AUTH0_API_AUDIENCE,
  //   redirectUri,
  //   // onRedirectCallback: (appState) => {
  //   //   console.log(appState);
  //   //   router.push(
  //   //     appState && appState.targetUrl
  //   //       ? appState.targetUrl
  //   //       : window.location.pathname,
  //   //   );
  //   // },
  //   onRedirectCallback: (appState) => {
  //     console.log('Cleaning Up History');
  //     window.history.replaceState({}, document.title, window.location.pathname);

  //     const nextRoute = appState && appState.targetUrl ? appState.targetUrl : window.location.pathname;
  //     console.log('Redirecting to Next Route: ' + nextRoute);

  //     router.push(nextRoute);
  //   },
  // });

  app.mount('#app');
}

async function invokeMain() {
  try {
    await main();
  } catch (e) {
    const message = 'Site May Be Down -- Please Check Internet Connection and Refresh\n\n' + e;
    console.error(e)
    alert(message);
  }
}

invokeMain();
