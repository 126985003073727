<!--
    An inline text component of a user, providing a tooltip with user information
-->

<template>
  <span>
    <b-tooltip :animated="false" :append-to-body="true" :auto-close="['outside']" :triggers="['click']" multilined
      position="is-right">
      <b-tag type="is-small" class="user-comp" ellipsis>
        {{ fullName }}
      </b-tag>
      <template v-slot:content>
        <div v-if="value.myAccountInfo" class="info-container">
          <label>Email: {{ value.myAccountInfo.email }}</label>
          <label>Phone: {{ value.myAccountInfo.phone }}</label>
          <label>Organization: {{ value.myAccountInfo.organizationName }}</label>
          <label>PC ID: {{ value.id }}</label>
          <label style="white-space: nowrap;">{{ value.userId }}</label>
          <label style="white-space: nowrap;" v-if="value?.myAccountInfo?.dealerName">
            {{ value.myAccountInfo.dealerName }}
          </label>
        </div>
        <div v-else>
          Missing MyAccount Info<br>
          {{ value }}
        </div>
      </template>
    </b-tooltip>
  </span>
</template>

<script>
import { getFullNameAny } from "../../services/UserUtil";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullName: (vm) => {
      return getFullNameAny(vm.value);
    },
  },
  methods: {}
};
</script>

<style lang="css" scoped>
.user-comp {
  cursor: context-menu;
}

.info-container {
  display: flex;
  flex-direction: column;
}
</style>
