<template>
  <div>
    <template v-if="detailedMode">
      <driver-stop v-for="o in stopsWithKey" :key="o.key" :value="o" :fg="o.fg" :bg="o.bg" @click="emitClick" />
    </template>

    <Warehouse v-if="warehouse" :warehouse="warehouse"/>
  </div>
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject.vue';
import Warehouse from '@/components/maps/Warehouse';
import DriverStop from '@/components/maps/DriverStop';
import Pp4Label from '@/components/maps/Label';

import { LatLngs, GeoJson } from '@/services/GeoUtil';

export default {
  extends: Pp4MapObject,
  components: {
    Warehouse, Pp4Label, DriverStop
  },
  data() {
    return {
      mapObjects: []
    };
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
    warehouse: {
      type: Object,
      default: null,
      required: false,
    },
    detailedMode: {
      type: Boolean,
      default: true,
      required: false,
    },
    colorScheme: {
      type: Object,
      required: false,
      default: null
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    stopsWithKey: (vm) => {
      const vals = vm.route.stops;

      return vals.map((val, i) => {
        const key = `${vm.route.id}:${val.rowPointer}`;

        const done = val.info;

        const labelText = `${done ? "🗸" : ""} ${val.stopOrder.toString()}`;
        const fg = vm.colorScheme?.fg.toHexString() || 'white';
        const bg = vm.colorScheme?.bg.toHexString() || 'black';
        const offsetInMeters = i % 2 === 0 ? i * 5 : i * -5;

        return { ...val, key, labelText, fg, bg, offsetInMeters };
      });
    },
  },
  watch: {
    route: 'mapReady',
    selected: 'mapReady',
  },
  mounted() { },
  unmounted() {
    this.clearMapObjects()
  },
  methods: {
    usedDirections() {
      const directions = this.route.directions;
      if (!directions) {
        return false;
      }

      const routes = directions.resultJson.routes;
      if (!routes || routes.length === 0) {
        return false;
      }

      const route = routes[0];
      const legs = route?.legs || [];
      const fg = this.colorScheme?.fg.toHexString() || 'white';
      const bg = this.colorScheme?.bg.toHexString() || 'black';

      legs.forEach((leg, i) => {
        let stopProps =
          i < this.stopsWithKey.length
            ? this.stopsWithKey[i]
            : {
              color: 'yellow',
              offsetInMeters: 0,
            };

        const steps = leg?.steps || [];

        steps.forEach((step, stepIndex) => {
          const polyline = step.polyline;
          let googleLatLngs = this.api.geometry.encoding.decodePath(polyline.points);

          if (googleLatLngs.length > 255) {
            const tmpLatLngs = googleLatLngs.map((ll) => ({ lat: ll.lat(), lng: ll.lng() }));
            const ls = LatLngs.toLineStringGeoJson(tmpLatLngs);
            GeoJson.simplify(ls, { tolerance: 0.0001, mutate: true });
            let newLatLngs = GeoJson.toLatLngs(ls);
            googleLatLngs = newLatLngs.map((ll) => new this.api.LatLng(ll.lat, ll.lng));
          }

          if (googleLatLngs.length < 2) {
            return;
          }

          googleLatLngs.forEach((ll) => {
            ll.lat = ll.lat();
            ll.lng = ll.lng();
          });

          const ls = LatLngs.toLineStringGeoJson(googleLatLngs);

          const offsetLs = GeoJson.lineOffset(ls, stopProps.offsetInMeters, { units: 'meters' });

          const offsetLatLngs = GeoJson.toLatLngs(offsetLs);

          const streetPolylineFg = new this.api.Polyline({
            map: this.map,
            path: offsetLatLngs,
            geodesic: true,
            strokeColor: this.selected ? fg : bg,
            strokeOpacity: this.selected ? 0.5 : 0.25,
            strokeWeight: this.selected ? 2 : 4,
            zIndex: 1
          });
          this.addPolyline(streetPolylineFg);

          if (this.selected) {
            const streetPolylineBg = new this.api.Polyline({
              map: this.map,
              path: offsetLatLngs,
              geodesic: true,
              strokeColor: bg,
              strokeOpacity: 1.0,
              strokeWeight: 5
            });
            this.addPolyline(streetPolylineBg);
          }
        });
      });

      return true;
    },
    emitClick() {
      this.$emit('click', this.pickupLocation);
    },
    addPolyline(pl) {
      pl.addListener('click', this.emitClick);

      this.mapObjects.push(pl);
    },
    clearMapObjects() {
      this.mapObjects.forEach(o => o.setMap(null))
      this.mapObjects = []
    },
    mapReady() {
      this.clearMapObjects();

      if (this.usedDirections()) {
        return;
      }

      const latLngs = this.route.stops.map((s) => s.location);

      const fg = this.colorScheme?.fg.toHexString() || 'white';
      const bg = this.colorScheme?.bg.toHexString() || 'black';

      const streetPolylineFg = new this.api.Polyline({
        map: this.map,
        path: latLngs,
        geodesic: true,
        strokeColor: this.selected ? fg : bg,
        strokeOpacity: this.selected ? 0.5 : 0.25,
        strokeWeight: this.selected ? 2 : 4,
        zIndex: 1
      });
      this.addPolyline(streetPolylineFg);

      if (this.selected) {
        const streetPolylineBg = new this.api.Polyline({
          map: this.map,
          path: latLngs,
          geodesic: true,
          strokeColor: bg,
          strokeOpacity: 1.0,
          strokeWeight: 5,
          zIndex: 0
        });
        this.addPolyline(streetPolylineBg);
      }
    },
  },
};
</script>

<style lang="css"></style>
