<template>
  <google-map mapDivId="mapdiv" @loaded="mapLoaded">
    <div class="route-wizard-wrapper">
      <LayerFilters />

      <sensible-sidebar position="left">
        <Tabs :titleMap="{'0': 'Pickups', '1': 'Dropoffs', '2': 'Locations', '3': 'Reviews'}">
          <template #0>
            <Tab title="Pickups" class="full-height-tab" :index="0">
              <pickup-requests-table />
            </Tab>
          </template>

          <template #1>
            <Tab title="Dropoffs" class="full-height-tab">
              <drop-requests-table/>
            </Tab>
          </template>

          <template #2>
            <Tab title="Locations" class="full-height-tab" >
              <pickup-locations-table />
            </Tab>
          </template>

          <template #3>
          <Tab title="Reviews" class="full-height-tab" >
            <driver-stop-reviews-table />
          </Tab>
          </template>
        </Tabs>
      </sensible-sidebar>

      <div id="mapdiv">
      </div>

      <sensible-sidebar position="right">
        <Tabs :titleMap="{ '0': 'Drivers', '1': 'History', '2': 'Users' }">
          <template #0>
            <Tab title="Drivers" class="full-height-tab" ><drivers-table /></Tab>
          </template>
          <template #1>
            <Tab title="History" class="full-height-tab" > <routes-table /></Tab>
          </template>
          <template #2>
            <Tab title="Users" class="full-height-tab" > <users-table /></Tab>
          </template>
        </Tabs>
      </sensible-sidebar>
    </div>

    <PageLoader v-if="loading" :label="loadingLabel" />

    <region-driver-configs v-if="showRegionDriverConfigs" />
    <region-pfpu-configs v-if="showRegionPfpuConfigs" />
  </google-map>
</template>

<script>
import GoogleMap from '@/components/maps/GoogleMap';
import MapControls from '@/components/maps/MapControls';
import LayerFilters from '@/components/maps/LayerFilters';

import DriverStopReviewsTable from './components/tables/DriverStopReviewsTable';
import SensibleSidebar from '@/components/common/SensibleSidebar';
import PageLoader from '@/components/common/PageLoader';
import RoutesTable from './components/tables/RoutesTable.vue';
import UsersTable from './components/tables/UsersTable.vue';
import PickupLocationsTable from './components/tables/PickupLocationsTable.vue';
import PickupRequestsTable from './components/tables/PickupRequestsTable.vue';
import RegionDriverConfigs from './components/RegionDriverConfigs';
import RegionPfpuConfigs from './components/RegionPfpuConfigs';
import DriversTable from './components/tables/DriversTable.vue';
import DropRequestsTable from './components/tables/DropRequestsTable.vue';

import Tabs from '@/components/common/Tabs';
import Tab from '@/components/common/Tab';

export default {
  components: {
    GoogleMap,
    MapControls,
    DriverStopReviewsTable,
    SensibleSidebar,
    PageLoader,
    LayerFilters,
    RoutesTable,
    UsersTable,
    PickupLocationsTable,
    PickupRequestsTable,
    DriversTable,
    DropRequestsTable,
    Tabs,
    RegionDriverConfigs,
    RegionPfpuConfigs,
    Tab,
  },
  data: function () {
    return {
      leftActiveTab: 0,
      rightActiveTab: 0,
      mapProvider: null,
    };
  },
  computed: {
    loading: vm => vm.$store.state.loading,
    loadingLabel: vm => vm.$store.state.loadingLabel,
    showRegionDriverConfigs: vm => vm.$store.state.layers.regionDriverConfigs,
    showRegionPfpuConfigs: vm => vm.$store.state.layers.regionPfpuConfigs
  },
  methods: {
    mapLoaded(provider) {
      this.mapProvider = provider;
    }
  },
  mounted() {
    this.$store.dispatch('getConstants');
    this.$store.dispatch('getUsers');
    this.$store.dispatch('getWarehouses');
  },
};
</script>

<style lang="css" scoped>
.route-wizard-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
}

.full-height-tab {
  flex-grow: 1;
  overflow: hidden;
}

#mapdiv {
  flex-grow: 1;
  height: 100%;
  position: relative;
}
</style>
