<template>
  <b-table :ref="theTable" :data="props.data" :detail-key="props.detailKey" :loading="loading"
    :paginated="pagination ? true : false" :backend-pagination="pagination ? true : false"
    :total="pagination ? pagination.total : 0" :current-page="pagination ? pagination.page : 0"
    :per-page="pagination ? pagination.perPage : 0" pagination-position="top" pagination-size="is-small"
    :pagination-rounded="true" :pagination-simple="false" :scrollable="true" :page-input="false"
    :debounce-page-input="500" hoverable custom-detail-row detailed bordered :disabledSearch="disableSearch"
    :narrowed="true" :sticky="true" :sticky-header="true" :sticky-headers="true" :show-detail-icon="true"
    @details-open="detailsOpened" :opened-detailed="data.openedRows" @page-change="emitPageChange"
    class="is-small is-size-7">

    <template #default>
      <b-table-column v-for="(item, index) in columns" :key="`${index}`" :field="item.field" :label="item.label"
        :visible="item.visible" :sortable="item.sortable" :centered="item.centered" v-slot="props">
        <slot :name="item.field" :value="props.row">{{
          item.field.split(".").reduce((r, k) => r?.[k], props.row)
          }}</slot>
      </b-table-column>

      <section>
        <div style="display: flex; flex-direction: column; width: 100%; padding: 0 5px;">
          <div v-if="search" class="controls-container"
            style="display: flex; flex-direction: column; width: 100%; margin-bottom: 5px">
            <span class="is-uppercase has-text-weight-bold">Search</span>
            <input id="pickup-request-user-search" placeholder="Press Enter" type="text" style="flex: 1"
              v-model="data.searchTerm" @change="emitSearchTerm" />
          </div>
          <div v-if="controls">
            <slot name="controls"></slot>
          </div>
        </div>
      </section>
    </template>

    <template #detail="props">
      <tr id="detailed-row">
        <td :colspan="columns.length + 1" style="padding: 0px; margin: 0px">
          <slot name="detail" :value="props.row"></slot>
        </td>
      </tr>
    </template>
    <template #top-left>
      <b-button v-if="showRefresh" @click="emitRefresh" icon-right="refresh" class="is-small" title="Refresh Table" />
      <slot name="top-left"></slot>
    </template>
  </b-table>
</template>

<script setup>
import { reactive, ref, watch } from 'vue';

const theTable = ref(null)

const props = defineProps({
  data: {
    type: Array,
    require: true,
  },
  columns: {
    type: Array,
    require: true,
  },
  detailKey: {
    type: String,
    require: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: "100%",
  },
  search: {
    type: Boolean,
    default: false,
    require: false,
  },
  controls: {
    type: Boolean,
    default: false,
    require: false,
  },
  disableSearch: {
    type: Boolean,
    default: false,
    require: false,
  },
  pagination: {
    type: Object,
    required: false,
  },
  snapTo: {
    require: false,
  },
  openRowAccess: {
    type: Array,
    required: false,
  },
  showRefresh: {
    type: Boolean,
    required: false,
    default: false,
  },
  multipleDetails: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const data = reactive({
  openedRows: [],
  searchTerm: "",
})

const $emit = defineEmits(["search", "pageChange", "rowOpened", "rowClosed", "refresh"]);

function emitRefresh() {
  $emit("refresh");
}

function emitSearchTerm() {
  $emit("search", data.searchTerm);
  // this.searchTerm = null;
}

function emitPageChange(event) {
  $emit("pageChange", event);
}

function detailsOpened(val) {
  if (props.multipleDetails) {
    data.openedRows.push(val[props.detailKey])
  }
  else {
    data.openedRows = [val[props.detailKey]]
  }
}

function scrollIntoView() {
  if (!data.openedRows.length) {
    return;
  }

  setTimeout(() => {
    const tableWrapper = theTable.value.querySelector(".table-wrapper");

    const detailedRow = tableWrapper.querySelector("table tbody tr#detailed-row");

    if (!detailedRow) {
      return;
    }

    const offsetTop = detailedRow.offsetTop;
    const tableClientHeight = tableWrapper.clientHeight;

    let newScrollTop = 0;
    if (offsetTop > tableClientHeight / 2) {
      newScrollTop = offsetTop;
      newScrollTop -= tableClientHeight / 2;
      newScrollTop += detailedRow.clientHeight / 2;
      newScrollTop += 64;
    }

    tableWrapper.scrollTop = newScrollTop;
  }, 100);
}

watch(() => props.openRowAccess, (val) => {
  data.openedRows = val;
})

watch(() => data.openedRows, (newOpenRows) => {
  if (newOpenRows.length == 0) {
    $emit("rowClosed");
  }
  if (newOpenRows.length == 1) {
    $emit("rowOpened", newOpenRows[0]);
    return;
  }

  if (!props.multipleDetails) {
    data.openedRows = [newOpenRows[1]];
    $emit("rowOpened", data.openedRows[0]);
  }
})

watch(() => props.snapTo, (row) => {
  if (data.openedRows.some(r => r === row)) {
    return // dupe event
  }

  data.openedRows = [row];
  scrollIntoView();
})
</script>

<style lang="css">
.b-table {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.b-table .table .checkbox-cell {
  width: initial !important;
  text-align: center !important;
}

.b-table .table-wrapper.has-sticky-header {
  height: 100% !important;
}
</style>
