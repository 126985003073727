<template>
   <div class="table-wrapper">
      <Table @rowOpened="rowOpenedHandler" :data="filteredRowsWithMetata" :columns="columns" :multipleDetails="false"
         detailKey="id" :loading="loading" :snapTo="selectedRowId" :controls="true">
         <template #controls>
            <filters :showTooltip="false">
               <div class="filters_columns">
                  <div class="filters_rows">
                     <span>
                        <label class="mr-1 ml-1" for="user-text-search">Search:
                        </label>
                        <input id="user-text-search" type="text" @input="searchTextChanged($event.target.value)" />
                     </span>
                  </div>
               </div>
            </filters>

            <b-button @click="refresh" size="is-small" icon-right="refresh" title="Refresh Table" class="mb-1" />
         </template>

         <template #detail="props">
            <user-edit :value="props.value" />
         </template>
      </Table>
   </div>
</template>

<script>
import Table from '@/components/common/Table';
import UserEdit from '@/views/RouteWizard/components/UserEdit';
import Pp4MapObject from '@/components/maps/Pp4MapObject';
import Filters from "@/components/common/Filters.vue"

export default {
   extends: Pp4MapObject,
   components: {
      Table, UserEdit,
      Filters
   },
   data() {
      return {
         showAssociationWorkflow: false,
         showVroom: false,
         selectedRowId: null,
         filters: {
            searchText: '',
         },
         columns: [
            { field: 'firstName', label: 'First', visible: true, sortable: true },
            { field: 'lastName', label: 'Last', visible: true, sortable: true },
            { field: 'userEmail', label: 'Email', visible: true, sortable: true }
         ],
      };
   },
   computed: {
      loading: vm => vm.$store.state.users.loading,
      rows: vm => vm.$store.state.users.data,
      rowsWithMetadata: vm => {
         return vm.rows.map(row => {
            let searchText = `${row.dealer} ${row.userEmail} ${row.userId}`.toLowerCase();
            if (row.myAccountInfo) {
               const m = row.myAccountInfo
               searchText += `${m.address1} ${m.city} ${m.dealerName} ${m.organizationName} ${m.firstName} ${m.lastName} ${m.phone}`.toLowerCase();
            }

            return {
               ...row,
               firstName: row.myAccountInfo?.firstName || '',
               lastName: row.myAccountInfo?.lastName || '',
               searchText
            }
         });
      },
      filteredRowsWithMetata: vm => {
         const lower = vm.filters.searchText.toLowerCase();

         return vm.rowsWithMetadata.filter(row => {
            return row.searchText.indexOf(lower) >= 0;
         });
      }
   },
   mounted() {
      this.refresh();
   },
   methods: {
      refresh() {
         this.$store.dispatch('getUsers');
      },
      searchTextChanged(val) {
         this.filters.searchText = val;
      },
      rowOpenedHandler(id) {
         if (this.selectedRowId === id) {
            return;
         }

         this.selectedRowId = id;
         // could do something here like panto
      },
   },
};
</script>

<style lang="css" scoped>
.table-wrapper {
   position: relative;
   justify-content: stretch;
   justify-items: stretch;
   align-items: stretch;
   align-content: stretch;
   min-height: 100%;
   display: flex;
   flex-direction: column;
   padding: 2px;
   min-width: 550px;
   max-width: 550px;
}

.filters_rows {
   display: flex;
   flex-direction: row;
}
</style>
