<template>
  <section>
    <b-sidebar type="is-light" v-model="open">
      <div class="p-1">
        Dispatcher
        <b-menu>
          <b-menu-list :label="currentUserFullName">
            <b-menu-item label="Logout" @click="logout"></b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import { getFullNameAny } from '../../services/UserUtil';

export default {
  data() {
    return {
      open: false,
    };
  },
  computed: {
    currentUser: (vm) =>
      vm.$store.getters.users.find(
        (u) => u.myAccountInfo?.userId === vm.$auth0.user.sub || u.userId === vm.$auth0.user.sub,
      ),
    currentUserFullName: (vm) => {
      if(! vm.currentUser) {
        return '';
      }

      const name = getFullNameAny(vm.currentUser);

      return name && name != 'unknown' ? name : vm.$auth0.user.nickname;
    }
  },
  methods: {
    logout() {
      this.$auth0.logout();
    },
    onOpen() {
      this.open = true;
    },
  },
};
</script>

<style>
.p-1 {
  padding: 1em;
}
</style>
