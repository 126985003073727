export function getFullNameAny(user) {

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user?.myAccountInfo) {
    const nameObject = user.myAccountInfo;
    return `${nameObject.firstName} ${nameObject.lastName}`;
  }

  if(user?.userEmail?.indexOf('@') >= 1) {
    return `${user.userEmail.split('@')[0]} (${user.id})`;
  }

  return user.userId
}

export function getFullName(user) {
  return user ? `${user.firstName} ${user.lastName}` : 'unknown';
}
