<template>
  <div>
    <span v-if="label" style="margin-right: 0.5em">{{ label }}</span>

    <div style="display: flex; align-items: center;">
      <slot :disabled="!editField"></slot>
      <span v-show="enabled && !editField" class="icon is-clickable" @click="beginEditField" style="margin-left: 0.2em;">
         <i class="mdi mdi-file-edit"></i> 
      </span>
      
      <span v-if="editField" class="icon is-clickable" @click="emitSave" title="Save" style="margin-left: 0.2em;">
        <i class="mdi mdi-content-save"></i>
      </span>

      <span v-if="editField" class="icon is-clickable" @click="reset" title="Undo" style="margin-left: 0.2em;">
        <i class="mdi mdi-undo"></i>
      </span>

      <span v-if="finalShowRemove" class="icon is-clickable ml-1" @click="emitRemove" title="Erase" style="margin-left: 0.2em;">
        <i class="mdi mdi-eraser"></i>
      </span>
  
    </div>
  </div>
</template>

<script>

export default {
  props: {
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
    },
    showRemove: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      editField: false,
    };
  },
  computed: {
    finalShowRemove: vm => {
      return vm.editField && vm.showRemove;
    },
  },
  methods: {
    reset() {
      this.$emit('onReset');
      this.editField = false;
    },
    beginEditField() {
      this.editField = true
    },
    emitSave() {
      this.$emit('onSave');
      this.editField = false;
    },
    emitRemove() {
      this.$emit("onRemove")
      this.editField = false;
    }
  }
};
</script>

<style>

</style>
